/* body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
  } */
  
  .loader {
    text-align: center;
  }
  
  #loading-text {
    font-size: 0.9em;
    animation: blink 1.5s infinite;
  }
  
  @keyframes blink {
    0%, 100% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
  }