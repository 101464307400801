.prose {
    max-width: 100% !important;
}

.prose * {
 font-size: .9rem !important;
}

.prose a {
    color: #3b82f6 !important; /* Apply the same color to all matching elements */
}


.prose li {
    margin: 0 !important; /* Reset margin for list items */
}

.p-1\.5 {
    padding: .175rem;
}

.prose p,
.prose ul,
.prose ol,
.prose h1,
.prose h2,
.prose h3,
.prose h4,
.prose h5,
.prose h6,
.prose code,
.prose strong {
    margin-top: 0.1em !important; /* Set a uniform top margin */
    margin-bottom: 0.1em !important; /* Set a uniform bottom margin */
    color: var(--text-color);
}

.full-width {
    width: 100%;
}

.code-container {
    position: relative;
}

/* Style for code block container */
.markdown pre {
    max-width: 100%;          /* Constrain to container width */
    overflow-x: auto;         /* Enable horizontal scrolling */
    white-space: pre;         /* Preserve whitespace formatting */
    word-wrap: normal;        /* Prevent text from wrapping within the code block */
}

/* Additional styles to enhance readability (optional) */
.markdown code {
    display: block;
    padding: 1em;
    border-radius: 5px;
    font-size: 0.9em;
    line-height: 1.5;
}
