@tailwind base;
@tailwind components;
@tailwind utilities;

/* For WebKit browsers */
::-webkit-scrollbar {
    width: 16px;
    background-color: var(--scrollbar-color);
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    border-radius: 4px;
  }
  
  /* For Firefox */
  * {
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-color);
  }
  
  /* Optional: For styling the scrollbar track in Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-color);
  }
  
.text-lg {
    font-size: .9rem;
    line-height: 1.75rem;
}

.text-sm {
    font-size: .8rem;
    line-height: 1.0rem;
}

.custom-close-button {
  position: relative;
  top: 10px; /* Adjust to move down */
}
